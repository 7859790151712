<template>
  <DashboardInformationWidgetOrganism :widget="widget" :isMobile="isMobile" />
</template>

<script>
import DashboardInformationWidgetOrganism from '@/components/Atomic/Organisms/Dashboard/DashboardInformationWidgetOrganism'
import resizeMixin from '@/mixins/resize'
import { get } from 'lodash'

export default {
  mixins: [resizeMixin],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { isMobile: false }
  },
  components: {
    DashboardInformationWidgetOrganism,
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.isMobile = size < 600 && size >= 96
      })
    },
  },
}
</script>
