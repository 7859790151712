var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardInformationWrapperStyled', {
    attrs: {
      "hasPartnerName": _vm.hasPartnerName
    }
  }, [_c('header', [_c('WidgetHeaderMolecule', {
    attrs: {
      "title": _vm.$t('widgetTypes.DASHBOARD_INFORMATION'),
      "assetDimensions": []
    }
  }), _vm.title ? _c('h2', [_vm._v(_vm._s(_vm.title))]) : _vm._e()], 1), _c('main', [_c('DashboardInformationDetailsStyled', _vm._l(_vm.mappedDashboardDetails, function (item, i) {
    return _c('div', {
      key: i
    }, [_c('DashboardInfoKeyValueAtom', {
      attrs: {
        "keyString": item.label,
        "valueString": item.value
      }
    })], 1);
  }), 0), _vm.hasPartnerName ? _c('LogoAtom', {
    attrs: {
      "path": _vm.imagePath
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }