var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('DashboardInformationWidget', {
    attrs: {
      "widget": _vm.widget,
      "dashboardDetails": _vm.dashboard,
      "isMobile": _vm.isMobile,
      "dashboardAssets": _vm.dashboardAssets
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }