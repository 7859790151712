var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardInfoKeyValueAtomStyled', [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.keyString))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.valueString))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }