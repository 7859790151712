<template>
  <LogoAtomStyled v-if="path">
    <img :src="path" />
  </LogoAtomStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const LogoAtomStyled = styled('div')`
  display: flex;
  justify-content: center;
  > img {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }
`
export default {
  components: {
    LogoAtomStyled,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
}
</script>
