<template>
  <DashboardInfoKeyValueAtomStyled>
    <div class="label">{{ keyString }}</div>
    <div class="value">{{ valueString }}</div>
  </DashboardInfoKeyValueAtomStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const DashboardInfoKeyValueAtomStyled = styled('div')`
  display: grid;
  grid-template-columns: 14rem max-content;
  grid-gap: 0.5rem;
  align-items: center;
  > .label {
    display: flex;
    justify-content: flex-start;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    color: ${p => p.theme.colors.muted};
  }
  > .value {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
  }
`
export default {
  components: {
    DashboardInfoKeyValueAtomStyled,
  },
  props: {
    keyString: {
      type: String,
      default: null,
    },
    valueString: {
      type: String,
      default: null,
    },
  },
}
</script>
