<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <DashboardInformationWidget
    v-else
    :widget="widget"
    :dashboardDetails="dashboard"
    :isMobile="isMobile"
    :dashboardAssets="dashboardAssets"
  />
</template>

<script>
import localesMixin from '@/mixins/locales'
import DashboardInformationWidget from '../../Molecules/DashboardInformationWidget'
import { TalpaLoaderWrapper } from '@common/components'
import { useAssetStore } from '@/stores/assets'
import DASHBOARD_QUERY from '#/graphql/operations/dashboard/dashboardList.gql'

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [localesMixin],
  inject: ['theme'],
  props: {
    widget: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      require: false,
    },
  },
  components: {
    DashboardInformationWidget,
    TalpaLoaderWrapper,
  },
  computed: {
    dashboardAssets() {
      return this.assetStore?.getDashboardAssets()?.length ?? 0
    },
  },
  apollo: {
    dashboard: {
      query: DASHBOARD_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return this.$route?.name !== 'dashboard' || !this.$route?.params?.id
      },
    },
  },
}
</script>
