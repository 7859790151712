var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.path ? _c('LogoAtomStyled', [_c('img', {
    attrs: {
      "src": _vm.path
    }
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }