var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardInformationWidgetOrganism', {
    attrs: {
      "widget": _vm.widget,
      "isMobile": _vm.isMobile
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }